import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import * as Sentry from '@sentry/react';

import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EMAIL_VERIFY_FAIL,
  USER_EMAIL_VERIFY_REQUEST,
  USER_EMAIL_VERIFY_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_EMAIL_VERIFY_CONFIRM_SUCCESS,
  USER_EMAIL_VERIFY_CONFIRM_REQUEST,
  USER_EMAIL_VERIFY_CONFIRM_FAIL,
  USER_PHONE_VERIFY_REQUEST,
  USER_PHONE_VERIFY_SUCCESS,
  USER_PHONE_VERIFY_FAIL,
  USER_PHONE_VERIFY_CONFIRM_REQUEST,
  USER_PHONE_VERIFY_CONFIRM_SUCCESS,
  USER_PHONE_VERIFY_CONFIRM_FAIL,
  USER_PREFERENCE_GET_REQUEST,
  USER_PREFERENCE_GET_SUCCESS,
  USER_PREFERENCE_GET_FAIL,
  USER_PREFERENCE_UPDATE_REQUEST,
  USER_PREFERENCE_UPDATE_SUCCESS,
  USER_PREFERENCE_UPDATE_FAIL,
  USER_TRANSACTION_LIST_REQUEST,
  USER_TRANSACTION_LIST_SUCCESS,
  USER_TRANSACTION_LIST_FAIL,
  USER_PASSWORD_RESET_VERIFY_REQUEST,
  USER_PASSWORD_RESET_VERIFY_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_FAIL,
  USER_TOS_READ_REQUEST,
  USER_TOS_READ_SUCCESS,
  USER_TOS_READ_FAIL,
} from '../constants/userConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import errorsToArrayString from '../utils/errorsToArrayString';
import { format, parse } from 'date-fns';
import errorHandler from '../utils/errorHandler';
import setAuthToken from '../utils/setAuthToken';

export const login =
  (email, phone, password, loginMedium, setIsExpired) => async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const requestBody = { password };

      if (loginMedium === 'email') {
        requestBody.email = email;
      } else if (loginMedium === 'phone') {
        requestBody.phone = phone;
      }

      const { data } = await axios.post(
        `${API_URI}/members/login/`,
        requestBody,
        config
      );

      // data.user.fresh_login = true;

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: { ...data, token: `Bearer ${data.token}` },
      });

      localStorage.setItem(
        'userInfo',
        JSON.stringify({ ...data, token: `Bearer ${data.token}` })
      );

      localStorage.setItem('popUpTime', new Date().toISOString());
      // localStorage.setItem('notificationPopUpTime', new Date().toISOString());

      setAuthToken(`Bearer ${data.token}`);
    } catch (error) {
      Sentry.captureException(error);
      const e = error?.response?.data;
      const errorText = errorHandler(e);

      if (
        errorText.includes('Account has been inactive for more than 6 months')
      ) {
        setIsExpired(true);
      } else dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('adminUserInfo');
  // localStorage.removeItem('popUpTime');
  // localStorage.removeItem('notificationPopUpTime');
  localStorage.removeItem('notificationSnackbarShown');
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
  dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
  dispatch({ type: USER_PASSWORD_RESET_RESET });
  delete axios.defaults.headers.common['Authorization'];
};

export const register =
  (name, email, phone, dob, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const values = { name, email, password };

      if (phone !== '+' && phone !== '+1' && phone !== null) {
        values.phone = phone;
      }

      if (dob !== null) {
        values.dob = dob.toISOString().split('T')[0];
      }

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/register/`,
        values,
        config
      );
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch(
        setAlert(
          'Registration successful! You will be redirected to the login page, please login with your newly registered account',
          'success'
        )
      );
    } catch (error) {
      // const errorText = formatError(error.response.data);

      Sentry.captureException(error);

      const errors = errorsToArrayString(error?.response?.data);

      if (errors && errors.isErrorArray) {
        dispatch(setAlert(errors.errorText, 'error'));
      }

      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const verifyUserEmail = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_EMAIL_VERIFY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.post(
      `${API_URI}/members/email_verify/`,
      config
    );
    dispatch({
      type: USER_EMAIL_VERIFY_SUCCESS,
      payload: data,
    });
    dispatch(
      setAlert(
        'An email with a verification code was just sent to your email address. Please click on the link and then submit the code to verify your email address.',
        'success'
      )
    );
  } catch (error) {
    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    } else dispatch(setAlert(error.response.data.detail, 'error'));

    dispatch({
      type: USER_EMAIL_VERIFY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const verifyUserEmailConfirm =
  (code, token) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_EMAIL_VERIFY_CONFIRM_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/email_confirm/`,
        { code, token },
        config
      );
      dispatch({
        type: USER_EMAIL_VERIFY_CONFIRM_SUCCESS,
        payload: data,
      });
      dispatch(
        setAlert(
          'Your email address has been successfully verified.',
          'success'
        )
      );
      dispatch(getUserDetails());
    } catch (error) {
      Sentry.captureException(error);

      const errors = errorsToArrayString(error?.response?.data);

      if (errors && errors.isErrorArray) {
        dispatch(setAlert(errors.errorText, 'error'));
      } else dispatch(setAlert(error.response.data.detail, 'error'));

      // const errorText = formatError(error.response.data);
      // dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_EMAIL_VERIFY_CONFIRM_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const verifyUserPhone = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PHONE_VERIFY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // console.log(userInfo);

    // Prepare axios header properties with userInfo.token in the auth header
    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    // Replace the previously set axios headers with the new config
    // axios.defaults.headers = config.headers;

    // console.log(axios.defaults.headers)

    const { data } = await axios.post(
      `${API_URI}/members/phone_verify/`,
      config
    );
    dispatch({
      type: USER_PHONE_VERIFY_SUCCESS,
      payload: data,
    });
    dispatch(
      setAlert(
        'An SMS with a verification code was just sent to your phone. Please check and submit the code.',
        'success'
      )
    );
  } catch (error) {
    Sentry.captureException(error);
    const e = error?.response?.data;
    const errorText = errorHandler(e);
    dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: USER_PHONE_VERIFY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const verifyUserPhoneConfirm =
  (code, token) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PHONE_VERIFY_CONFIRM_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/phone_confirm/`,
        { code, token },
        config
      );
      dispatch({
        type: USER_PHONE_VERIFY_CONFIRM_SUCCESS,
        payload: data,
      });
      dispatch(
        setAlert('Your phone number has been successfully verified.', 'success')
      );
      dispatch(getUserDetails());
    } catch (error) {
      // const errorText = formatError(error.response.data);
      // dispatch(setAlert(errorText, 'error'));

      Sentry.captureException(error);

      const errors = errorsToArrayString(error?.response?.data);

      if (errors && errors.isErrorArray) {
        dispatch(setAlert(errors.errorText, 'error'));
      } else dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: USER_PHONE_VERIFY_CONFIRM_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const resetPassword =
  (email, phone, resetMedium) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const requestBody = {};

      if (resetMedium === 'email') {
        requestBody.email = email;
      } else if (resetMedium === 'phone') {
        requestBody.phone = phone;
      }

      const { data } = await axios.post(
        `${API_URI}/members/password_reset/`,
        requestBody,
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const e = error?.response?.data;
      const errorText = errorHandler(e);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_PASSWORD_RESET_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const resetPasswordVerify = (phone, otp) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PASSWORD_RESET_VERIFY_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post(
      `${API_URI}/members/password_reset/`,
      {
        phone,
        otp,
      },
      config
    );
    dispatch({
      type: USER_PASSWORD_RESET_VERIFY_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const e = error?.response?.data;
    const errorText = errorHandler(e);
    dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: USER_PASSWORD_RESET_VERIFY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const resetPasswordConfirm =
  (new_password1, new_password2, uid, token) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/password_reset_confirm/`,
        { new_password1, new_password2, uid, token },
        config
      );
      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_SUCCESS,
        payload: data,
      });
      dispatch(
        setAlert(
          'Password has been reset with the new password, you will be redirected to the login page',
          'success'
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      const e = error?.response?.data;
      const errorText = errorHandler(e);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_PASSWORD_RESET_CONFIRM_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const changePassword =
  (new_password1, new_password2) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PASSWORD_CHANGE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/password_change/`,
        { new_password1, new_password2 },
        config
      );
      dispatch({
        type: USER_PASSWORD_CHANGE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const e = error?.response?.data;
      const errorText = errorHandler(e);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_PASSWORD_CHANGE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.get(`${API_URI}/members/me/`, config);

    if (data.dob !== null) {
      const parsedDate = parse(data.dob, 'yyyy-MM-dd', new Date());
      data.dob = format(parsedDate, 'MMMM dd, yyyy');
    }

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    const e = error?.response?.data;
    const errorText = errorHandler(e);
    dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getUserPreferences = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PREFERENCE_GET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.get(`${API_URI}/members/preferences/`, config);

    dispatch({
      type: USER_PREFERENCE_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    } else dispatch(setAlert(error.response.data.detail, 'error'));

    dispatch({
      type: USER_PREFERENCE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserPreferences = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PREFERENCE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.post(
      `${API_URI}/members/preferences/`,
      values,
      config
    );

    // Set member notification preferences to configured
    await axios.post(`${API_URI}/members/notification/configured/`, {}, config);

    dispatch({
      type: USER_PREFERENCE_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_PREFERENCE_GET_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Notification preference updated', 'success'));
    dispatch(getUserDetails());
    // dispatch(getUserPreferences());
  } catch (error) {
    // let errorText = '';
    // for (const [key, value] of Object.entries(error.response.data)) {
    //   value.forEach((errorMsg) => {
    //     errorText = errorText + ` | [${key.toUpperCase()}] ${errorMsg}`;
    //   });
    // }
    // dispatch(setAlert(errorText.substring(2, errorText.length), 'error'));

    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    } else {
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));
    }

    dispatch({
      type: USER_PREFERENCE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile =
  (values, fileName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      // check if values.dob exists and is not null and it is an date object
      if (values.dob && values.dob !== null && typeof values.dob === 'object') {
        values.dob = format(values.dob, 'yyyy-MM-dd').toString();
      }

      if (values.phone === '+' || values.phone === '') {
        delete values.phone;
      }

      // Getting token from redux state
      const {
        userLogin: { userInfo },
      } = getState();

      // Preparing axios header properties
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: userInfo.token,
        },
      };

      // Creating Form Data object
      const getFormData = (object) =>
        Object.keys(object).reduce((formData, key) => {
          if (key === 'avatar') {
            // modify the fileName by adding timestamp to it
            const timestamp = new Date().getTime();
            const newFileName = `${timestamp}_${fileName}`;
            formData.append('avatar', object[key], newFileName);
          } else formData.append(key, object[key]);

          return formData;
        }, new FormData());

      // POSTing the formdata to the endpoint
      const { data } = await axios.post(
        `${API_URI}/members/me/`,
        getFormData(values),
        config
      );

      // Dispatching redux success event for this action
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      // Checking if fresh login or not and displaying alert accordingly
      if (values.fresh_login) {
        dispatch(
          setAlert(
            'Profile updated, you will be redirected to password update page',
            'success'
          )
        );
      } else dispatch(setAlert('Profile updated', 'success'));

      // Fetching updated user details
      // dispatch(getUserDetails());
    } catch (error) {
      Sentry.captureException(error);
      const e = error?.response?.data;
      const errorText = errorHandler(e);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const updateUserProfilePreferences =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      // console.log(values);

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/members/me/`,
        values,
        config
      );

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      dispatch(setAlert('Spa preference updated', 'success'));

      // dispatch(getUserDetails());
    } catch (error) {
      // const errorText = formatError(error.response.data);
      // dispatch(setAlert(errorText, 'error'));

      Sentry.captureException(error);

      const errors = errorsToArrayString(error?.response?.data);

      if (errors && errors.isErrorArray) {
        dispatch(setAlert(errors.errorText, 'error'));
      } else {
        const errorText = formatError(error.response.data);
        dispatch(setAlert(errorText, 'error'));
      }

      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserTransactions =
  (transactionPageSize) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_TRANSACTION_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.get(
        `${API_URI}/transactions/?page=1&page_size=${transactionPageSize}`,
        config
      );

      dispatch({
        type: USER_TRANSACTION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);

      const errors = errorsToArrayString(error?.response?.data);

      if (errors && errors.isErrorArray) {
        dispatch(setAlert(errors.errorText, 'error'));
      } else {
        const errorText = formatError(error?.response?.data);
        dispatch(setAlert(errorText, 'error'));
      }

      dispatch({
        type: USER_TRANSACTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userTosRead = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TOS_READ_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.post(`${API_URI}/members/tos/`, config);
    dispatch({
      type: USER_TOS_READ_SUCCESS,
      payload: data,
    });
    // dispatch(
    //   setAlert('Your email address has been successfully verified.', 'success')
    // );
    dispatch(getUserDetails());
  } catch (error) {
    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    } else dispatch(setAlert(error.response.data.detail, 'error'));

    // const errorText = formatError(error.response.data);
    // dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: USER_TOS_READ_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
