import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import MessageIcon from '@mui/icons-material/Message';
import {
  AppBar,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import ConversationInterface from '../components/Conversation/ConversationInterface';
import ConversationInfo from '../components/ConversationInfo';
import ConversationList from '../components/ConversationList';
import ConversationOnline from '../components/ConversationOnline';
import SearchField from '../components/SearchField';
import MenuIcon from '@mui/icons-material/Menu';
import { isMobile } from 'react-device-detect';

const ConversationScreen = () => {
  const [selectedConversation, setSelectedConversation] = React.useState(null);
  const [alignment, setAlignment] = React.useState('web');
  const [value, setValue] = React.useState(0);
  const [mobileDisplay, setMobileDisplay] = React.useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [isClosing, setIsClosing] = React.useState(false);

  const [mobileInfoOpen, setMobileInfoOpen] = React.useState(false);
  const [isInfoClosing, setIsInfoClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleInfoDrawerClose = () => {
    setIsInfoClosing(true);
    setMobileInfoOpen(false);
  };

  const handleInfoDrawerTransitionEnd = () => {
    setIsInfoClosing(false);
  };

  const handleInfoDrawerToggle = () => {
    if (!isClosing) {
      setMobileInfoOpen(!mobileOpen);
    }
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const drawer = (
    <div>
      {/* {!isMobile && ( */}
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          width: '100%',
        }}
      >
        <Typography
          variant="p"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Conversations
        </Typography>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <CancelIcon color="white" />
        </IconButton>
      </Toolbar>
      {/* )} */}

      <Divider />
      <SearchField />
      <Divider />
      <Typography color={'primary'} fontWeight={'bold'} m={1}>
        Active now
      </Typography>
      <ConversationOnline />
      <Divider />
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        fullWidth
        size="small"
      >
        <ToggleButton
          value="chats"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MessageIcon />
          Chats
        </ToggleButton>
        <ToggleButton
          value="favorites"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FavoriteIcon />
          Favorites
        </ToggleButton>
        <ToggleButton
          value="blocks"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BlockIcon />
          <p>Blocks</p>
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider />
      <Typography color={'primary'} fontWeight={'bold'} m={1}>
        Recent Chats
      </Typography>
      <ConversationList
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
      />
    </div>
  );

  return (
    <Box
      mt={9}
      sx={{
        marginBottom: '-50px',
      }}
    >
      <Grid
        container
        spacing={0}
        // width={'100vw'}
        // height={'100vh'}
      >
        <Grid
          item
          xs={0}
          sm={2}
          sx={{
            overflow: 'auto',
            height: '80vh',
          }}
        >
          <Box
            component="nav"
            // sx={{
            //   width: { sm: '100%' },
            //   flexShrink: { sm: 0 },
            // }}
            aria-label="chat list"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {isMobile && (
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '100%',
                  },
                }}
                PaperProps={{
                  style: {
                    position: 'absolute',
                    top: '62px',
                  },
                }}
              >
                {drawer}
              </Drawer>
            )}
          </Box>
          <Box
            component="nav"
            sx={{
              display: { xs: 'none', sm: 'block' },
              flexShrink: { sm: 0 },
            }}
            aria-label="chat list"
          >
            {drawer}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            height: '80vh',
          }}
          p={1}
        >
          <ConversationInterface />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            display: {
              xs: mobileDisplay === 'Info' ? 'block' : 'none',
              sm: 'block',
            }, // Hide on mobile
            height: '80vh',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <ConversationInfo
            mobileInfoOpen={mobileInfoOpen}
            handleInfoDrawerClose={handleInfoDrawerClose}
            handleInfoDrawerTransitionEnd={handleInfoDrawerTransitionEnd}
            selectedConversation={selectedConversation}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: 'block', sm: 'none' }, // Hide on mobile
          }}
        >
          <BottomNavigation
            showLabels
            value={mobileDisplay}
            onChange={(event, newValue) => {
              setMobileDisplay(newValue);
            }}
          >
            <BottomNavigationAction
              label="Conversations"
              onClick={handleDrawerToggle}
              icon={<MessageIcon />}
            />
            <BottomNavigationAction
              label="Info"
              onClick={handleInfoDrawerToggle}
              icon={
                selectedConversation ? (
                  <Avatar
                    alt={selectedConversation?.name}
                    src={selectedConversation?.image}
                  />
                ) : (
                  <InfoIcon />
                )
              }
            />
          </BottomNavigation>
        </Grid>
      </Grid>

      {/* <ConversationsSource /> */}
    </Box>
  );
};

export default ConversationScreen;
