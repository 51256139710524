import BlockIcon from '@mui/icons-material/Block';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Gallery from './Gallery';
import CancelIcon from '@mui/icons-material/Cancel';

const infoDrawerWidth = '100%';

const ConversationInfo = (props) => {
  const {
    window,
    mobileInfoOpen,
    handleInfoDrawerClose,
    handleInfoDrawerTransitionEnd,
    selectedConversation,
  } = props;

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [isOnline, setIsOnline] = useState(true); // State to track online status

  return (
    <Box
      // component="nav"
      // sx={{
      //   // width: { sm: infoDrawerWidth },
      //   // flexShrink: { sm: 0 },
      //   position: 'relative',
      //   top: 18,
      //   height: '88%',
      // }}
      aria-label="masseuse info"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        anchor="right"
        open={mobileInfoOpen}
        onTransitionEnd={handleInfoDrawerTransitionEnd}
        onClose={handleInfoDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: infoDrawerWidth,
          },
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            top: '62px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {!selectedConversation && (
            <Typography variant="h6" component="div">
              Select a recent chat to view info about the chat{' '}
            </Typography>
          )}
          {selectedConversation && (
            <Gallery
              images={[
                selectedConversation?.image,
                ...selectedConversation?.images,
              ]}
              thumbnails={[
                selectedConversation?.image,
                ...selectedConversation?.thumbnails,
              ]}
            />
          )}
          <Box
            display={'flex'}
            pt={2}
            sx={{
              width: '100%',
              borderBottom: '1px solid #f5f5f5',
            }}
          ></Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            pt={2}
          >
            <Chip
              label="Online"
              color="success"
              variant="outlined"
              icon={<FiberManualRecordIcon />}
            />
            <IconButton aria-label="fingerprint" color="primary">
              <FavoriteBorderIcon />
            </IconButton>
            <IconButton aria-label="fingerprint" color="primary">
              <BlockIcon />
            </IconButton>
          </Box>
          <Box
            display={'flex'}
            pt={2}
            sx={{
              width: '100%',
              borderBottom: '1px solid #f5f5f5',
            }}
          ></Box>

          {selectedConversation && (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} gap={1}>
                <Chip
                  label={selectedConversation.age}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.background}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.language}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.technique}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.tattoos ? 'Yes' : 'No'}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={
                    selectedConversation.bust_enhanced ? 'Enhanced' : 'Natural'
                  }
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.measurements}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.measurements}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.measurements}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.hair_color}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />

                <Chip
                  label={selectedConversation.services}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.height}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
                <Chip
                  label={selectedConversation.new ? 'Yes' : 'No'}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
              </Grid>
            </Grid>
          )}

          <Box
            display={'flex'}
            pt={2}
            sx={{
              width: '100%',
              borderBottom: '1px solid #f5f5f5',
            }}
          ></Box>
          <Typography
            variant="h6"
            component="div"
            style={{
              color: '#c3892b',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Availabilities
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            pt={1}
            gap={1}
          >
            {selectedConversation &&
            selectedConversation.availabilities.length > 0 ? (
              selectedConversation.availabilities.map((s, i) => (
                <Chip key={i} label={s} color="primary" />
              ))
            ) : (
              <Chip label="N/A" color="error" />
            )}
          </Box>
          <Box
            display={'flex'}
            pt={2}
            sx={{
              width: '100%',
              borderBottom: '1px solid #f5f5f5',
            }}
          ></Box>
          <Typography variant="body2" mt={1}>
            {selectedConversation?.bio}
          </Typography>
          <br />
          <Button
            onClick={handleInfoDrawerClose}
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </Drawer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        {!selectedConversation && (
          <Typography variant="h6" component="div">
            Select a recent chat to view info about the chat
          </Typography>
        )}
        {selectedConversation && (
          <Gallery
            images={[
              selectedConversation?.image,
              ...selectedConversation?.images,
            ]}
            thumbnails={[
              selectedConversation?.image,
              ...selectedConversation?.thumbnails,
            ]}
          />
        )}
        <Box
          display={'flex'}
          pt={2}
          sx={{
            width: '100%',
            borderBottom: '1px solid #f5f5f5',
          }}
        ></Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          pt={2}
        >
          <Chip
            label="Online"
            color="success"
            variant="outlined"
            icon={<FiberManualRecordIcon />}
          />
          <IconButton aria-label="fingerprint" color="primary">
            <FavoriteBorderIcon />
          </IconButton>
          <IconButton aria-label="fingerprint" color="primary">
            <BlockIcon />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          pt={2}
          sx={{
            width: '100%',
            borderBottom: '1px solid #f5f5f5',
          }}
        ></Box>

        {selectedConversation && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} gap={1}>
              <Chip
                label={selectedConversation.age}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.background}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.language}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.technique}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.tattoos ? 'Yes' : 'No'}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={
                  selectedConversation.bust_enhanced ? 'Enhanced' : 'Natural'
                }
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.measurements}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.measurements}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.measurements}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.hair_color}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />

              <Chip
                label={selectedConversation.services}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.height}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
              <Chip
                label={selectedConversation.new ? 'Yes' : 'No'}
                color="primary"
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
            </Grid>
          </Grid>
        )}

        <Box
          display={'flex'}
          pt={2}
          sx={{
            width: '100%',
            borderBottom: '1px solid #f5f5f5',
          }}
        ></Box>
        <Typography
          variant="h6"
          component="div"
          style={{
            color: '#c3892b',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Availabilities
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          pt={1}
          gap={1}
        >
          {selectedConversation &&
          selectedConversation.availabilities.length > 0 ? (
            selectedConversation.availabilities.map((s, i) => (
              <Chip key={i} label={s} color="primary" />
            ))
          ) : (
            <Chip label="N/A" color="error" />
          )}
        </Box>
        <Box
          display={'flex'}
          pt={2}
          sx={{
            width: '100%',
            borderBottom: '1px solid #f5f5f5',
          }}
        ></Box>
        <Typography variant="body2" mt={1}>
          {selectedConversation?.bio}
        </Typography>
      </Box>
    </Box>
  );
};

export default ConversationInfo;
