import React from 'react';
import { TextField, InputAdornment, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchField() {
  return (
    <Box p={1}>
      <TextField
        variant="outlined"
        placeholder="Search"
        sx={{
          borderRadius: '50px', // Rounded border
          '& .MuiOutlinedInput-root': {
            borderRadius: '50px',
            '& fieldset': {
              borderRadius: '50px', // Ensures the border is rounded
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        size="small"
        fullWidth
      />
    </Box>
  );
}

export default SearchField;
